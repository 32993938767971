import React from 'react';
import { ChooseCharity as Organism } from '@components/Organisms';

export const ChooseCharity = ({
  display,
  title,
  subtitle,
  charityCard,
  bannerImage,
  peepingBennyImage,
}) => {
  return (
    <Organism
      display={display}
      title={title}
      subtitle={subtitle}
      charityCards={charityCard}
      bannerImage={bannerImage}
      peepingBennyImage={peepingBennyImage}
    />
  );
};
