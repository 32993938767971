import React, { useCallback } from 'react';
import { fromMarkdown } from '@lib/utils';
import { Postcode } from '@components/Organisms/Postcode/Postcode';
import { CtaT } from '@components/Molecules/Cta/types';
import EverydayFibreCard from './components/EverydayFibreCard';
import { CardPackageT } from '@components/Organisms/ChoosePackage/types';
import styled from 'styled-components';
import { Button, TrustBox } from '@components/Atoms';
import { mockTrustBoxData } from '@components/Atoms/TrustBox/__mockData__';
import { breakpoints } from '@theme/breakpoints';
import usePostcodeContext from '@hooks/usePostcodeContext';
import { useRouter } from 'next/router';
import { isExistingCustomerCheck, postcodeSubmitCallback } from '@lib/utils/uprn';
import Link from 'next/link';
import { ButtonType } from '@components/Atoms/Button/types';
import { UprnStatus } from '@lib/types';

const STYLED = {
  wrapper: styled.section`
    padding: 2rem 0;
    @media ${breakpoints.desktop} {
      padding-top: 4rem;
      padding-bottom: 4rem;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      gap: 2rem;

      & > * {
        &:first-child {
          flex: 2;
        }
        &:last-child {
          flex: 1;

      }
    }
  `,
  preHeading: styled.p`
    font-size: 1.5rem;
    font-weight: 600;
    font-family: var(--font-geomanist);
    color: black;
    @media ${breakpoints.desktop} {
      font-size: 2.5rem;
      max-width: 40rem;
    }
    @media ${breakpoints.desktopLarge} {
      font-size: 3rem;
    }
  `,
  heading: styled.h2`
    font-size: 3.5rem;
    margin-bottom: 0.5rem;
    color: var(--color-primary);
    line-height: 1;
    font-weight: 600;
    background: -webkit-linear-gradient(180deg, #f4111b 10%, #0402fd);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    max-width: 25rem;
    @media ${breakpoints.tablet} {
      max-width: 100%;
      font-size: 4rem;
      max-width: 32rem;
    }
    @media ${breakpoints.desktop} {
      font-size: 4.5rem;
      max-width: 40rem;
    }
    @media ${breakpoints.desktopLarge} {
      font-size: 5.5rem;
    }
  `,
  body: styled.div`
    max-width: 25rem;
    @media ${breakpoints.tablet} {
      max-width: 60%;
    }
    @media ${breakpoints.desktop} {
      max-width: 45rem;
    }
    * {
      font-size: 1.125rem;
      color: black;
      margin-bottom: 1rem;
      font-weight: 300;
      @media ${breakpoints.desktop} {
        font-size: 1.25rem;
      }
      @media ${breakpoints.desktopLarge} {
        font-size: 1.5rem;
      }
    }
  `,
  postcode: styled.div`
    margin-top: 1rem;

    [class*='StyledPostcodeContainer'] {
      margin-top: 1rem;
    }

    h3 {
      font-size: 1.4rem;
      color: var(--color-primary);
      margin-top: 2rem;
      margin-bottom: -1.5rem;
      font-weight: 600;
    }
  `,
  trustpilot: styled.div`
    height: 24px;
    width: 300px;
  `,
  eligibleHeading: styled.h3`
    font-size: 1.75rem;
    color: var(--color-primary);
    margin-top: 2rem;
    font-weight: 500;
    max-width: 25rem;
    line-height: 1.1;

    @media ${breakpoints.tablet} {
      max-width: 24rem;
      font-size: 2rem;
    }
  `,
  eligibleBody: styled.p`
    font-size: 1.125rem;
    color: black;
    margin-bottom: 1rem;
    font-weight: 300;
  `,
  eligibleButton: styled.div`
    margin-top: 2rem;
    margin-bottom: 3rem;
  `,
};

interface PropsI {
  display: boolean;
  preHeading: string;
  heading: string;
  body: string;
  productFeatures: string;
  cta: CtaT;
  products: CardPackageT[];
  productCard: {
    productFeatures: string;
    benefitTag: string;
  };
}

export const EverydayHero = (props) => {
  const router = useRouter();
  const { isCurrentPostcodeEligible, setHasConfirmedAddress, setPostcodeItem, clearPostcode } =
    usePostcodeContext();
  const { display, preHeading, heading, body, products, productCard }: PropsI = props;

  const { productFeatures, benefitTag } = productCard;

  const everydayProduct = products.find((product) => product.name === 'Everyday Fibre');

  const handlePostcodeSubmit = useCallback(
    (data) => {
      if (isExistingCustomerCheck(data.coverage, data.oss_type)) {
        return router.push('/error/existing-customer');
      }
      postcodeSubmitCallback({
        data,
        ineligibleCallback: () => {
          return router.push('/service-unavailable');
        },
        successCallback: () => {
          setPostcodeItem(data);
          setHasConfirmedAddress(true);
        },
      });
    },
    [router, setHasConfirmedAddress, setPostcodeItem, clearPostcode],
  );

  if (!display) return null;
  return (
    <div className="container">
      <STYLED.wrapper>
        <header>
          <STYLED.preHeading>{preHeading}</STYLED.preHeading>
          <STYLED.heading>{heading}</STYLED.heading>
          <STYLED.body>{fromMarkdown({ text: body, isHeading: false })}</STYLED.body>
          <STYLED.postcode>
            {!isCurrentPostcodeEligible && (
              <>
                <h3>Let&apos;s start by checking if we&apos;re in your area</h3>
                <Postcode
                  showTitle={false}
                  inline={true}
                  onSubmit={handlePostcodeSubmit}
                  placeholder="Enter your postcode to find out!"
                  shouldRedirect={false}
                />
              </>
            )}
          </STYLED.postcode>
          {isCurrentPostcodeEligible && (
            <>
              <STYLED.eligibleHeading>
                Congrats! HeyBroadband is in your area
              </STYLED.eligibleHeading>
              <STYLED.eligibleBody>You can now apply for Everyday Fibre</STYLED.eligibleBody>
              <STYLED.eligibleButton>
                <Link href="#everyday-fibre-form" passHref>
                  <Button buttonType={ButtonType.PRIMARY}>
                    <a href="#everyday-fibre-form">Apply for Everyday Fibre</a>
                  </Button>
                </Link>
              </STYLED.eligibleButton>
            </>
          )}
          <STYLED.trustpilot>
            <TrustBox data={mockTrustBoxData} />
          </STYLED.trustpilot>
        </header>
        <EverydayFibreCard
          productFeatures={productFeatures}
          product={everydayProduct}
          benefitTag={benefitTag}
        />
      </STYLED.wrapper>
    </div>
  );
};
