import React from 'react';
import { ReferAFriend as Organism } from '@components/Organisms';

export const ReferAFriend = ({ display, preTitle, title, body, cta, image }) => {
  return (
    <Organism
      display={display}
      preTitle={preTitle}
      title={title}
      body={body}
      cta={cta}
      image={image}
    />
  );
};
